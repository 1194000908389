import { EntityBaseModel } from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { AdminDiscountStore } from "../entities/AdminDiscount";

export class AdminDiscountModel extends EntityBaseModel {
  static _store: AdminDiscountStore;

  @observable
  percent?: number;

  @observable
  amount?: number;

  @observable
  min_purchase?: number;

  @observable
  title?: string;

  @observable
  description?: string;

}
