import { EntityBaseModel } from '@devslane/mobx-entity-manager';
import { observable } from 'mobx';
import { UserAddressStore } from "../entities/UserAddressStore";

export class UserAddressModel extends EntityBaseModel {
    static _store: UserAddressStore;

    @observable
    name!: string;

    @observable
    user_id!: number;

    @observable
    address_line_1!: string;

    @observable
    city!: string;

    @observable
    state!: string;

    @observable
    pin_code!: string;

    @observable
    country!: string;

    @observable
    latitude!: string;

    @observable
    longitude!: string;
}
