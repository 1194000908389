import { EntityBaseModel } from '@devslane/mobx-entity-manager';
import { observable } from 'mobx';
import { CleaningBuildingStore } from "../entities/CleaningBuildingStore";

export class CleaningBuildingModel extends EntityBaseModel {
    static _store: CleaningBuildingStore;

    @observable
    name!: string;

    @observable
    address_line!: string;

    @observable
    city!: string;

    @observable
    state!: string;

    @observable
    pincode!: string;

    @observable
    country!: string;

    @observable
    latitude!: string;

    @observable
    longitude!: string;

    @observable
    full_address!: string;
}
