import { Redirect, Route, RouteProps } from "react-router";
import React from "react";
import { observer } from "mobx-react";
import { AppContext } from "../../App.context";
import { LoginStatus } from "../../store";
import { TopRoundedSpinner } from "../spinner/TopRoundedSpinner";
const ProtectedRoute: React.FC<RouteProps> = ({ component, ...props }) => {
  const {
    store: {
      userStore: { loginStatus },
    },
  } = AppContext;

  switch (loginStatus) {
    case LoginStatus.LOGGED_IN:
      return <Route component={component} {...props} />;

    case LoginStatus.LOGGED_OUT:
      return (
        <Route
          render={(props) => (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )}
          {...props}
        />
      );
    
    case LoginStatus.PENDING:
    default:
      return (
        <Route
          render={() => (
            <div
              className={
                "h-screen w-screen z-50 flex justify-center items-center"
              }
            >
              <TopRoundedSpinner size={"lg"} />
            </div>
          )}
          {...props}
        />
      );
  }
};

export default observer(ProtectedRoute);
