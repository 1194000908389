export class NavigationService {
    private static _instance: NavigationService;

    private constructor() {
       
    }

    static getInstance(): NavigationService {
        if (!this._instance) {
            this._instance = new NavigationService();
        }

        return this._instance;
    }
}
