import React from "react";
import { CustomButton } from "../shared-resources/custom-components/CustomButton";
import SvgIconComponent from "../shared-resources/svgIconComponent/svgIcon.component";
import { SvgIconType } from "../shared-resources/svgIconComponent/svgIcons.list";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Home from "../home/Home";
import Services from "../services/Services";
import { useHistory, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PersonIcon from "@mui/icons-material/Person";

import {
  AboutRoutes,
  AddressRoutes,
  AuthRoutes,
  BookingRoutes,
  HomeRoutes,
  ServicesRoutes,
  UserRoutes,
} from "../../constants/enums/RouteEnums";
import classNames from "classnames";
import { Close } from "@mui/icons-material";
import { AppContext } from "../../App.context";
import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";

const drawerWidth = 240;

const NavBar: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { store } = AppContext;
  const userStore = store.userStore;
  const loginStatus = userStore.isLoggedIn;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <div className="flex-col w-full h-full shadow-2xl bg-primaryWhite text-black">
      <div className="w-full text-right pr-3p">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { lg: "none" } }}
        >
          <Close />
        </IconButton>
      </div>
      <List>
        {[
          {
            text: "Home",
            route: "/",
          },
          {
            text: "Services",
            route: ServicesRoutes._ROOT,
          },
          {
            text: "About",
            route: AboutRoutes._ROOT,
          },
        ].map((obj, index) => (
          <ListItem
            button
            key={obj.text}
            onClick={() => {
              history.push(obj.route);
              handleDrawerToggle();
            }}
          >
            <ListItemText primary={obj.text} className="text-center" />
          </ListItem>
        ))}
      </List>
      <Divider />
      <div className="flex-col w-full justify-around items-center py-2">
        {loginStatus ? (
          <List>
            {[
              {
                text: "Profile",
                route: UserRoutes.PROFILE,
              },
              {
                text: "My Addresses",
                route: AddressRoutes._ROOT,
              },
              {
                text: "My Bookings",
                route: BookingRoutes._ROOT,
              },
            ].map((obj, index) => (
              <ListItem
                button
                key={obj.text}
                onClick={() => {
                  history.push(obj.route);
                  handleDrawerToggle();
                }}
              >
                <ListItemText primary={obj.text} className="text-center" />
              </ListItem>
            ))}
            <ListItem
              button
              onClick={() => {
                store.userStore.reset();
                store.userAddressStore.reset();
                history.push(HomeRoutes._ROOT);
                handleDrawerToggle();
              }}
            >
              <ListItemText primary={`Logout`} className="text-center" />
            </ListItem>
          </List>
        ) : (
          <div className="flex w-full justify-center">
            <div className={"w-20 h-6"}>
              <CustomButton
                type={"button"}
                className={"text-xs w-full h-full"}
                theme={"black"}
                buttonStyle={{ borderRadius: "rounded-md" }}
                onClick={async () => {
                  if (!loginStatus) {
                    await store.userStore.setLastPath(location.pathname);

                    history.push(AuthRoutes.LOGIN);
                    handleDrawerToggle();
                  } else {
                    await store.userStore.reset();
                  }
                }}
              >
                {`Login`}
              </CustomButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="hideScrollBar">
      <nav className="flex justify-between bg-white shadow-xl h-14 md:h-18 sticky top-0 z-50 lg:px-6">
        <section className="flex w-full justify-center md:justify-between items-center pl-4 md:space-x-4">
          <div
            className="hidden lg:flex w-20 md:w-48 cursor-pointer"
            onClick={() => history.push(HomeRoutes._ROOT)}
          >
            <SvgIconComponent icon={SvgIconType.fullLogo} />
          </div>
          <div
            className="w-48 lg:hidden cursor-pointer"
            onClick={() => history.push(HomeRoutes._ROOT)}
          >
            <SvgIconComponent icon={SvgIconType.fullLogo} />
          </div>
          <nav className="w-12 lg:hidden absolute right-1">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { lg: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              component="nav"
              sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
              aria-label="mailbox folders"
            >
              <Drawer
                anchor="right"
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: "50%",
                    height: "auto",
                  },
                }}
              >
                {drawer}
              </Drawer>
            </Box>
          </nav>
        </section>
        <section className="hidden lg:flex text-xs space-x-4 md:text-xl md:space-x-5 h-full items-center">
          {[
            {
              text: "Home",
              route: "/",
            },
            {
              text: "Services",
              route: ServicesRoutes._ROOT,
            },
            {
              text: "About",
              route: AboutRoutes._ROOT,
            },
          ].map((obj, index) => (
            <div key={index} className="w-16 h-5 md:w-24 md:h-8">
              <CustomButton
                type={"button"}
                className={
                  "text-xs w-full h-full focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50 hover:bg-primaryGrey md:text-xl"
                }
                theme={"tertiary"}
                buttonStyle={{
                  padding: "2xl:py-3",
                  borderRadius: "rounded-md",
                }}
                onClick={() => history.push(obj.route)}
              >
                {obj.text}
              </CustomButton>
            </div>
          ))}
          <div className="w-24 h-5 md:w-32 md:h-9 hidden">
            <CustomButton
              type={"button"}
              className={
                "text-xs  justify-around w-full h-full hover:bg-primaryGrey md:text-xl  "
              }
              theme={"secondary"}
              buttonStyle={{
                padding: "px-2 md:px-2",
                borderRadius: "rounded-md border-2 border-black",
              }}
              trailingIcon={
                <SvgIconComponent
                  className="w-2 md:w-3"
                  icon={SvgIconType.dropDownArrow}
                />
              }
            >
              {`Location`}
            </CustomButton>
          </div>
          {loginStatus ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar
                      sx={{
                        width: { md: 30, lg: 32, xl: 40 },
                        height: { md: 30, lg: 32, xl: 40 },
                      }}
                    ></Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={() => {
                    history.push(UserRoutes.PROFILE);
                  }}
                >
                  <PersonIcon sx={{ marginRight: 1 }} /> Profile
                </MenuItem>
                <Divider />
                {/* <MenuItem
                  onClick={() => {
                    history.push(AddressRoutes._ROOT);
                  }}
                >
                  <HomeIcon sx={{ marginRight: 1 }} /> My Addresses
                </MenuItem>
                <Divider /> */}
                <MenuItem
                  onClick={() => {
                    history.push(BookingRoutes._ROOT);
                  }}
                >
                  <ListAltIcon sx={{ marginRight: 1 }} /> My Bookings
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={async () => {
                    await store.userStore.reset();
                    await store.userAddressStore.reset();
                    history.push(HomeRoutes._ROOT);
                  }}
                >
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : (
            <div className="w-20 h-5 md:w-28 md:h-9">
              <CustomButton
                type={"button"}
                className={"text-xs w-full h-full md:text-xl"}
                theme={"primary"}
                buttonStyle={{
                  padding: "2xl:py-3",
                  borderRadius: "rounded-md",
                }}
                onClick={async () => {
                  if (!loginStatus) {
                    await store.userStore.setLastPath(location.pathname);

                    history.push(AuthRoutes.LOGIN);
                  } else {
                    await store.userStore.reset();
                  }
                }}
                hoverEffect={true}
              >
                {`Login`}
              </CustomButton>
            </div>
          )}
        </section>
        <div
          className={classNames({
            "xl:pl-4.2": location.pathname !== HomeRoutes._ROOT,
          })}
        ></div>
      </nav>
    </div>
  );
};

export default observer(NavBar);
