import { action } from "mobx";
import { ChildStore } from "./ChildStore";
import { UserStore } from "./entities/UserStore";
import { BookingPartnerStore } from "./entities/BookingPartnerStore";
import { CleaningPartnerStore } from "./entities/CleaningPartnerStore";
import { BookingPartnerPackageStore } from "./entities/BookingPartnerPackageStore";
import { PartnerBookingStore } from "./entities/PartnerBookingStore";
import { CleaningBuildingStore } from "./entities/CleaningBuildingStore";
import { CleaningBookingStore } from "./entities/CleaningBookingStore";
import { UserAddressStore } from "./entities/UserAddressStore";
import { CarTypeStore } from "./entities/CarTypeStore";
import { CarBrandStore } from "./entities/CarBrandStore";
import { CleaningBuildingPackageStore } from "./entities/CleaningBuildingPackage";
import { AdminDiscountStore } from "./entities/AdminDiscount";

export class RootStore {
  private static _instance: RootStore;

  userStore: UserStore;
  userAddressStore: UserAddressStore;
  bookingPartnerStore: BookingPartnerStore;
  bookingPartnerPackageStore: BookingPartnerPackageStore;
  partnerBookingStore: PartnerBookingStore;
  cleaningPartnerStore: CleaningPartnerStore;
  cleaningBuildingStore: CleaningBuildingStore;
  cleaningBookingStore: CleaningBookingStore;
  carTypeStore: CarTypeStore;
  carBrandStore: CarBrandStore;
  adminDiscountStore: AdminDiscountStore;
  cleaningBuildingPackageStore: CleaningBuildingPackageStore;

  private constructor() {
    // We will pass the instance of RootStore in ChildStore for better accessibility of siblings
    this.userStore = new UserStore(this);
    this.userAddressStore = new UserAddressStore(this);
    this.bookingPartnerStore = new BookingPartnerStore(this);
    this.bookingPartnerPackageStore = new BookingPartnerPackageStore(this);
    this.partnerBookingStore = new PartnerBookingStore(this);
    this.cleaningPartnerStore = new CleaningPartnerStore(this);
    this.cleaningBuildingStore = new CleaningBuildingStore(this);
    this.cleaningBookingStore = new CleaningBookingStore(this);
    this.carTypeStore = new CarTypeStore(this);
    this.carBrandStore = new CarBrandStore(this);
    this.adminDiscountStore = new AdminDiscountStore(this);
    this.cleaningBuildingPackageStore = new CleaningBuildingPackageStore(this);
  }

  static getInstance(): RootStore {
    if (!this._instance) {
      this._instance = new RootStore();
    }

    return this._instance;
  }

  @action
  logout(): void {
    Object.values(this)
      .filter((v) => v instanceof ChildStore)
      .forEach((v) => (v as ChildStore<any>).reset());
  }
}
