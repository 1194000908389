import { action, observable } from 'mobx';
import { ChildStore } from '../ChildStore';
import { RootStore } from '../RootStore';
import { ModelList } from "@devslane/mobx-entity-manager";
import { UserAddressModel } from "../models/UserAddressModel";
import { userService } from "../services/api-services/UserService";
import { AppContext } from "../../App.context";

export class UserAddressStore extends ChildStore<UserAddressModel> {
    constructor(rootStore: RootStore) {
        super(rootStore);
        UserAddressModel._store = this;
    }

    @observable
    userAddresses: ModelList<UserAddressModel> = new ModelList<UserAddressModel>(UserAddressModel);

    @action
    async listUserAddresses(): Promise<void> {
      
        return await userService.listUserAddresses().then((res) => {
           
            return this.userAddresses.setItems(res.user_addresses);
        });
    }

    @action
    async addAddress(data: { name: string, address_line_1: string, city: string, state: string, country: string, pin_code: string }): Promise<void> {
        return await userService.addAddress(data).then((res) => {
            AppContext.store.userAddressStore.userAddresses.appendItem(res.user_address);
        });
    }

    @action
    async updateAddress(data: { name: string, address_line_1: string, city: string, state: string, country: string, pin_code: string }, id: number): Promise<void> {
        return await userService.updateAddress(data, id).then((res) => {
            const oldAddresses = this.userAddresses.items.filter(e => e.id !== id);
            this.userAddresses.setItems([...oldAddresses, res.user_address]);
        });
    }

    reset(): void {
        this.entities.clear();
    }
}
