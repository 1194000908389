import {
  EntityBaseModel,
  ModelItem,
  ModelJson,
} from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { CarType } from "../../enums/CarType";
import { CleaningBookingStore } from "../entities/CleaningBookingStore";
import { InstaCleaningPlanType } from "../../enums/InstaCleaningPlanType";
import { CleaningPartnerModel } from "./CleaningPartnerModel";
import { BookingPartnerModel } from "./BookingPartnerModel";
import { CleaningBuildingModel } from "./CleaningBuildingModel";
import { BookingStatusType } from "../../enums/BookingStatusType";
import { CleaningBookingStatusType } from "../../enums/CleaningBookingStatusType";
import { PaymentStatus } from "../../enums/PaymentStatus";

export class CleaningBookingModel extends EntityBaseModel {
  static _store: CleaningBookingStore;

  @observable
  cleaning_partner_building_id!: number;

  @observable
  user_id!: number;

  @observable
  order_id!: string;

  @observable
  start_date!: string;

  @observable
  end_date!: string;

  @observable
  start_time!: string;

  @observable
  end_time!: string;

  @observable
  car_number!: string;

  @observable
  flat_detail!: string;

  @observable
  parking_number!: string;

  @observable
  car_brand!: { name: string };

  @observable
  car_model!: { name: string };

  @observable
  final_price!: number;

  @observable
  discount!: number;

  @observable
  status!: CleaningBookingStatusType;

  @observable
  transaction_id!: string;

  @observable
  payment_order_id!: string;

  @observable
  payment_status!: PaymentStatus;

  @observable
  cleaning_partner?: ModelItem<CleaningPartnerModel>;

  @observable
  cleaning_building?: ModelItem<CleaningBuildingModel>;

  deserialize_cleaningPartner(data: ModelJson) {
    this.cleaning_partner = new ModelItem<CleaningPartnerModel>(
      CleaningPartnerModel
    );
    this.cleaning_partner.deserialize(data);
  }

  deserialize_cleaningBuilding(data: ModelJson) {
    this.cleaning_building = new ModelItem<CleaningBuildingModel>(
      CleaningBuildingModel
    );
    this.cleaning_building.deserialize(data);
  }
}
