import { EntityBaseModel, ModelItem, ModelJson } from '@devslane/mobx-entity-manager';
import { observable } from 'mobx';
import { CarType } from "../../enums/CarType";
import { PartnerBookingStore } from "../entities/PartnerBookingStore";
import { BookingPartnerModel } from "./BookingPartnerModel";
import { BookingPartnerPackageModel } from "./BookingPartnerPackageModel";
import { BookingStatusType } from "../../enums/BookingStatusType";

export class PartnerBookingModel extends EntityBaseModel {
    static _store: PartnerBookingStore;

    @observable
    partner_id!: number

    @observable
    user_id!: number

    @observable
    washing_package_id!: number;

    @observable
    washing_bay_number!: number;

    @observable
    detailing_package_id!: number;

    @observable
    detailing_bay_number!: number;

    @observable
    car_type!: CarType;

    @observable
    start_time!: string;

    @observable
    end_time!: string;

    @observable
    car_number!: string;

    @observable
    status!: BookingStatusType;

    @observable
    partner?: ModelItem<BookingPartnerModel>;

    @observable
    package?: ModelItem<BookingPartnerPackageModel>;

    deserialize_partner(data: ModelJson) {
        this.partner = new ModelItem<BookingPartnerModel>(BookingPartnerModel);
        this.partner.deserialize(data);
    }

    deserialize_package(data: ModelJson) {
        this.package = new ModelItem<BookingPartnerPackageModel>(BookingPartnerPackageModel);
        this.package.deserialize(data);
    }
}
