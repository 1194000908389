import { action, observable } from 'mobx';
import { ChildStore } from '../ChildStore';
import { RootStore } from '../RootStore';
import { BookingPartnerModel } from "../models/BookingPartnerModel";
import { ModelList } from "@devslane/mobx-entity-manager";
import { BookingPartnerPackageModel } from "../models/BookingPartnerPackageModel";
import { bookingPartnerService } from "../services/api-services/BookingPartnerService";
import { CarType } from "../../enums/CarType";
import { ServiceType } from "../../enums/ServiceType";

export class BookingPartnerPackageStore extends ChildStore<BookingPartnerModel> {
    constructor(rootStore: RootStore) {
        super(rootStore);
        BookingPartnerPackageModel._store = this;
    }

    @observable
    bookingPartnerPackageList: ModelList<BookingPartnerPackageModel> = new ModelList<BookingPartnerPackageModel>(BookingPartnerPackageModel);

    @action
    async getPartnerPackages(data: {  partnerId: number, date: string, car_type: CarType, service_type: ServiceType}): Promise<void> {
        try {
            const packages = (await bookingPartnerService.getPartnerPackage(data)).packages;
            
            this.bookingPartnerPackageList.deserialize(packages);
        } catch (e) {
            throw e;
        }
    }

    reset(): void {

        this.entities.clear();
    }
}
