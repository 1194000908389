import { EntityBaseModel } from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { CarTypeStore } from "../entities/CarTypeStore";

export class CarTypeModel extends EntityBaseModel {
  static _store: CarTypeStore;

  @observable
  name?: string;

  @observable
  car_type?: string;
}
