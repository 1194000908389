import ReactDOM from "react-dom";
import "./styles/index.css";
import "@fontsource/poppins";
import App from "./App";
import { Context } from "./store/Context";
import { AppContext } from "./App.context";
import { StorageService } from "./store/services/StorageService";
import { ToastService } from "./store/services/ToastService";
import { NavigationService } from "./store/services/NavigationService";
import * as Sentry from "@sentry/react";
Sentry.init({
  dsn: "https://619b95d262a4433b9cfd5dd24cb44b97@o4505030507823104.ingest.sentry.io/4505048019435520",
});
Context.boot({
  storageService: StorageService.getInstance(),
  baseUrl: process.env.REACT_APP_BACKEND_BASE_URL as string,
  toastService: ToastService.getInstance(),
  navigationService: NavigationService.getInstance(),
});
AppContext.boot();
ReactDOM.render(<App />, document.getElementById("root"));
