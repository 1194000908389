import React from "react";
import classNames from "classnames";
import { ThemeType } from "../../enums/ThemeType";
import { AbbreviatedSize } from "../../enums/size/AbbreviatedSize";

export interface Props {
  size: AbbreviatedSize;
  theme?: ThemeType;
}

export const TopRoundedSpinner: React.FunctionComponent<Props> = ({
  size,
  theme,
}) => {
  const spinnerClassForSize: any = {
    xs: "h-5 w-5",
    sm: "h-10 w-10",
    md: "h-15 w-15",
    lg: "h-20 w-20",
    xl: "h-25 w-25",
  };

  return (
    <div
      className={classNames(
        "animate-spin",
        "rounded-full",
        "border-t-8",
        spinnerClassForSize[size],
        `border-${theme}-900`
      )}
    />
  );
};

TopRoundedSpinner.defaultProps = {
  size: "md",
  theme: "warn",
};

export default React.memo(TopRoundedSpinner);
