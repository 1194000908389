import dropDownArrow from './svg-icons/dropdown-arrow.svg';
import washing from './svg-icons/washing.svg';
import deepCleaning from './svg-icons/deep-cleaning.svg';
import instaCleaning from './svg-icons/insta-clean-logo.svg';
import location from './svg-icons/location.svg';
import whiteLocation from './svg-icons/white-location.svg';
import facebookIcon from './svg-icons/facebook_icon.svg';
import twitterIcon from './svg-icons/twitter_icon.svg';
import instagramIcon from './svg-icons/instagram_icon.svg';
import linkedInIcon from './svg-icons/linkedIn_icon.svg';
import eye from './svg-icons/eye.svg';
import eyeSlash from './svg-icons/eyeSlash.svg';
import calendar from './svg-icons/calendar.svg';
import priceTag from './svg-icons/price_tag.svg';
import packageCar from './svg-icons/package-car.svg';
import exclamation from './svg-icons/exclamation.svg';
import cross from './svg-icons/cross.svg';
import arrowRight from './svg-icons/arrow_right.svg';
import fullLogo from './svg-icons/full-logo.svg'

export enum SvgIconType {
    dropDownArrow = 'dropDownArrow',
    fullLogo = 'fullLogo',
    washing = 'washing',
    deepCleaning = 'deepCleaning',
    instaCleaning = 'instaCleaning',
    location = 'location',
    whiteLocation = 'whiteLocation',
    facebookIcon = 'facebookIcon',
    twitterIcon = 'twitterIcon',
    instagramIcon = 'instagramIcon',
    linkedInIcon = 'linkedInIcon',
    eye = 'eye',
    eyeSlash = 'eyeSlash',
    calendar = 'calendar',
    priceTag = 'priceTag',
    packageCar = 'packageCar',
    exclamation = 'exclamation',
    cross = 'cross',
    arrowRight = 'arrowRight',
}

export const svgList: { [key in keyof typeof SvgIconType]: string } = {
    dropDownArrow,
    fullLogo,
    washing,
    deepCleaning,
    instaCleaning,
    location,
    whiteLocation,
    facebookIcon,
    twitterIcon,
    instagramIcon,
    linkedInIcon,
    eye,
    eyeSlash,
    calendar,
    priceTag,
    packageCar,
    exclamation,
    cross,
    arrowRight,
};

export default svgList;
