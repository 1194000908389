import "./styles/app.css";
import React, { useEffect } from "react";
import NavBar from "./components/nav-bar/NavBar";
import { BrowserRouter, Switch } from "react-router-dom";
import CustomerDashboardLazy from "./components/CustomerDashboard.lazy";
import PublicRoute from "./components/routes/PublicRoute";
import { AppContext } from "./App.context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import CustomerProtectedDashboardLazy from "./components/CustomerProtectedDashboard.lazy";

declare global {
  interface Window {
    Razorpay: any;
  }
}
const App: React.FC = () => {
  const { store, toast, storage } = AppContext;
  useEffect(() => {
    const authToken = storage.getAuthToken();
    if (!store.userStore.loggedInUser) {
      //&& authToken
      try {
        store.userStore.me();
      } catch (e) {
        toast.showError("Logged Out");
      }
    }
  }, []);
  return (
    <div
      className="flex flex-col h-screen overflow-x-hidden hideScrollBar "
      style={{ backgroundColor: "#e3e3e3" }}
    >
      <ToastContainer hideProgressBar pauseOnFocusLoss={false} />
      <BrowserRouter>
        <NavBar />
        <Switch>
          {/* <ProtectedRoute path={'/admin'} component={AdminDashboardLazy} /> */}
          {/*<ProtectedRoute path={'/partner'} component={PartnerDashboardLazy} />*/}
          {/*<ProtectedRoute path={'/:userId'} component={CustomerDashboardLazy} />*/}
          <ProtectedRoute
            path={"/user"}
            component={CustomerProtectedDashboardLazy}
          />
          <PublicRoute path={"/"} component={CustomerDashboardLazy} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
