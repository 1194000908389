import { action, observable } from "mobx";
import { ChildStore } from "../ChildStore";
import { RootStore } from "../RootStore";
import {
  ModelItem,
  ModelList,
  PaginatedModelList,
} from "@devslane/mobx-entity-manager";
import { BookingPartnerPackageModel } from "../models/BookingPartnerPackageModel";
import { bookingPartnerService } from "../services/api-services/BookingPartnerService";
import { CarType } from "../../enums/CarType";
import { ServiceType } from "../../enums/ServiceType";
import { PartnerBookingModel } from "../models/PartnerBookingModel";
import { CleaningPartnerModel } from "../models/CleaningPartnerModel";
import { CleaningBookingModel } from "../models/CleaningBookingModel";
import { InstaCleaningPlanType } from "../../enums/InstaCleaningPlanType";
import { cleaningPartnerService } from "../services/api-services/CleaningPartnerService";
import { CleaningBuildingModel } from "../models/CleaningBuildingModel";
import { BookingListType } from "../../enums/BookingListType";
import { partnerBookingService } from "../services/api-services/PartnerBookingService";
import { cleaningBookingService } from "../services/api-services/CleaningBookingService";
import { PaymentStatus } from "../../enums/PaymentStatus";

export class CleaningBookingStore extends ChildStore<CleaningPartnerModel> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    CleaningBookingModel._store = this;
  }

  @observable
  booking: ModelItem<CleaningBookingModel> = new ModelItem<CleaningBookingModel>(
    CleaningBookingModel
  );

  @observable
  bookingDetail: ModelItem<CleaningBookingModel> = new ModelItem<CleaningBookingModel>(
    CleaningBookingModel
  );

  @observable
  userBookingsList: ModelList<CleaningBookingModel> = new ModelList<CleaningBookingModel>(
    CleaningBookingModel
  );

  @observable
  serviceType!: ServiceType;

  @observable
  carBrandId!: number;

  @observable
  carModelId!: number;

  @observable
  carTypeId!: number;

  @observable
  packageId!: number;

  @observable
  discountId!: number;

  @observable
  discountDetail!: {
    title?: string;
    description?: string;
    min_purchase?: number;
    amount?: number;
  };

  @observable
  costPrice!: number;

  @observable
  sellingPrice!: number;

  @observable
  discount!: number;

  @observable
  gst!: number;

  @observable
  finalPrice!: number;

  @observable
  carBrand!: string;

  @observable
  carModel!: string;

  @observable
  vehicleNumber!: string;

  @observable
  flatDetail!: string;

  @observable
  parkingNumber!: string;

  @observable
  startDate!: string;

  @observable
  endDate!: string;

  @observable
  startTime!: string;

  @observable
  endTime!: string;

  @observable
  selectedBuildingId!: number;

  @observable
  selectedBuildingPackageId!: number;

  @observable
  selectedPlanPrice!: string;

  @observable
  selectedPartnerId!: number;

  @action
  async listBookings(): Promise<void> {
    try {
      const cleaningBookings = (await cleaningBookingService.listBookings())
        .cleaning_bookings;
      this.userBookingsList.setItems(
        cleaningBookings.map(
          (cleaningBooking) =>
            CleaningBookingModel.fromJson(
              cleaningBooking
            ) as CleaningBookingModel
        )
      );
    } catch (e) {
      throw e;
    }
  }

  @action
  async getById(data: { id: number }): Promise<void> {
    try {
      const cleaning_booking = (await cleaningBookingService.getById(data))
        .cleaning_booking;

      this.bookingDetail.setItem(
        CleaningBookingModel.fromJson(cleaning_booking) as CleaningBookingModel
      );
    } catch (e) {
      throw e;
    }
  }

  @action
  async createBooking(data: {
    cleaning_building_id: number;
    cleaning_partner_id: number;
    cleaning_building_package_id: number;
    start_date: string;
    end_date: string;
    start_time: string;
    end_time: string;
    car_number: string;
    flat_detail: string;
    parking_number: string;
    car_type_id: number;
    car_model_id: number;
    car_brand_id: number;
    cost_price: number;
    selling_price: number;
    discount: number;
    discount_id: number;
    gst: number;
    final_price: number;
    payment_order_id?: string;
    transaction_id?: string;
    payment_status?: PaymentStatus;
  }): Promise<void> {
    try {
      const booking = (await cleaningBookingService.createBooking(data)) 
        .booking;

      this.booking.setItem(
        CleaningBookingModel.fromJson(booking) as CleaningBookingModel
      );
      this.listBookings();
    } catch (e) {
      throw e;
    }
  }

  @action
  async updateBooking(data: any, id: string): Promise<void> {
    try {
      const booking = (await cleaningBookingService.updateBooking(data, id))
        .booking;

      this.booking.setItem(
        CleaningBookingModel.fromJson(booking) as CleaningBookingModel
      );
      this.listBookings();
    } catch (e) {
      throw e;
    }
  }
  @action
  async validateBooking(data: {
    cleaning_building_id: number;
    cleaning_partner_id: number;
    cleaning_building_package_id: number;
    start_date: string;
    end_date: string;
    start_time: string;
    end_time: string;
    car_number: string;
    car_type_id: number;
    car_model_id: number;
    car_brand_id: number;
  }): Promise<void> {
    try {
      await cleaningBookingService.validateBooking(data);
    } catch (e) {
     
      throw e;
    }
  }

  reset(): void {
    this.entities.clear();
  }
}
