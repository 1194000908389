import { ContextStatic, ModelJson } from '@devslane/mobx-entity-manager';
import { CarType } from "../../../enums/CarType";
import { ServiceType } from "../../../enums/ServiceType";
import { format } from "date-fns";

class BookingPartnerService {
    static getInstance(): BookingPartnerService {
        return new BookingPartnerService();
    }

    async list(data: { date: string, car_type: string, service_type: string }): Promise<{ partners: ModelJson[] }> {
       
        return await ContextStatic.default.apiService
            .get('/partners', {
                params: data, extras: {
                    useAuth: false
                }
            })
            .then((res: any) => {
                return {
                    // partners: BookingPartnerModel.fromJson({ ...(res?.partners || []) }) as unknown as BookingPartnerModel[],
                    partners: res.partners,
                };
            });
    }

    async getPartnerPackage(data: { date: string, partnerId: number, car_type: CarType, service_type: ServiceType }): Promise<{ packages: ModelJson[] }> {
        return await ContextStatic.default.apiService
            .get('/partners/' + `${data.partnerId}` + '/packages', {
                params: {
                    date: format(new Date(data.date), "yyyy-MM-dd"),
                    car_type: data.car_type,
                    service_type: data.service_type
                }, extras: {
                    useAuth: false
                }
            })
            .then((res: any) => {
                res.slot_availability.forEach((e: any) => {
                    const index = res.packages.findIndex((pack: any) => pack.id === e.id);
                 
                    res.packages[index]['slot_availability'] = e.slots;
                });
                
                return {
                    // packages: BookingPartnerPackageModel.fromJson({ ...(res?.data || []) }) as unknown as BookingPartnerPackageModel[],
                    packages: res.packages
                };
            });
    }

    async getById(data: { id: number }): Promise<{ booking: ModelJson }> {
        return await ContextStatic.default.apiService
            .get(`/bookings/${data.id}`)
            .then((res: any) => {
                return {
                    booking: res.booking
                };
            });
    }

    async createBooking(data: { partner_id: number, car_type: CarType, start_time: string, end_time: string, car_number: string, washing_package_id?: number, detailing_package_id?: number }): Promise<{ booking: ModelJson }> {
        return await ContextStatic.default.apiService
            .post('/bookings', data, {
                extras: {
                    useAuth: true
                }
            })
            .then((res: any) => {
                return {
                    booking: res.booking
                };
            });
    }
}

export const bookingPartnerService = BookingPartnerService.getInstance();
