import { action, observable } from "mobx";
import { ChildStore } from "../ChildStore";
import { RootStore } from "../RootStore";
import { PaginatedModelList } from "@devslane/mobx-entity-manager";
import { CleaningPartnerModel } from "../models/CleaningPartnerModel";
import { cleaningPartnerService } from "../services/api-services/CleaningPartnerService";
import { CleaningBuildingModel } from "../models/CleaningBuildingModel";

export class CleaningBuildingStore extends ChildStore<CleaningPartnerModel> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    CleaningBuildingModel._store = this;
  }

  @observable
  cleaningBuildingsList: PaginatedModelList<CleaningBuildingModel> = new PaginatedModelList<CleaningBuildingModel>(
    CleaningBuildingModel
  );

  @observable
  availableTimeSlots!: { id: number; item: string }[];

  @action
  async listCleaningBuildings(): Promise<void> {
    try {
      await cleaningPartnerService.listCleaningBuildings().then((res) => {
        this.cleaningBuildingsList.deserialize(res.cleaning_buildings);
      });
    } catch (e) {
      throw e;
    }
  }

  @action
  async listTimeSlotForCleaningBuilding(
    id: number,
    startDate: string,
    endDate: string
  ): Promise<void> {
    try {
      await cleaningPartnerService
        .listTimeSlotForCleaningBuilding(id, startDate, endDate)
        .then((res) => {
          this.availableTimeSlots = res.time_slot;
        });
    } catch (e) {
      throw e;
    }
  }

  reset(): void {
    this.entities.clear();
  }
}
