import { Route, RouteProps } from 'react-router';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AppContext } from "../../App.context";
import { useHistory } from "react-router-dom";
import { AuthRoutes } from "../../constants/enums/RouteEnums";

const PublicRoute: React.FC<RouteProps> = ({ component, ...props }) => {
    const { store, storage } = AppContext;
    const history = useHistory();
    useEffect(() => {
        // storage.getAuthToken().then(() => store.userStore.me()).catch(() => history.push(AuthRoutes.LOGIN));
            (async () => {
                const authToken = await storage.getAuthToken();
                if (authToken) {
                    await store.userStore.me();
                }
            })();
    }, []);
    return <Route component={component} {...props} />;
};

export default observer(PublicRoute);
