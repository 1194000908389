import classNames from 'classnames';
import React from 'react';
import { ThemeType } from '../../../enums/ThemeType';

export interface Props {
    type?: 'button' | 'submit';
    className?: string;
    onClick?: (e?: any) => void;
    children?: string;
    theme?: ThemeType;
    disabled?: boolean;
    borderWidth?: string;
    isSubmitting?: boolean;
    trailingIcon?: any;
    buttonStyle?: {
        padding?: string;
        borderRadius?: string;
    };
    name?: string;
    hoverEffect?: boolean;
    scaleEffect?: boolean;
}

export const CustomButton: React.FC<Props> = ({
    type,
    className,
    children,
    onClick,
    theme,
    disabled,
    borderWidth,
    isSubmitting,
    trailingIcon,
    buttonStyle,
    name,
    hoverEffect,
    scaleEffect,
}) => {
    const classForTheme: any = {
        primary: 'text-white bg-primaryBlack',
        secondary: 'text-black bg-primaryWhite',
        tertiary: 'text-black',
        black: 'text-white bg-black',
    };

    return (
        <button
            type={type}
            name={name}
            onClick={onClick}
            disabled={disabled}
            className={classNames(
                'inline-flex relative',
                'justify-center',
                'items-center',
                'focus:outline-none',
                disabled ? ' bg-gray opacity-50 cursor-not-allowed' : '',
                className,
                classForTheme[theme!],
                buttonStyle?.padding ? buttonStyle?.padding : 'px-4 py-2',
                buttonStyle?.borderRadius ? buttonStyle?.borderRadius : 'rounded-xl',
                hoverEffect ? 'hover:bg-primaryGrey hover:text-black' : '',
                scaleEffect ? 'transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105' : '',
            )}
        >
            {children}
            {!isSubmitting && trailingIcon && trailingIcon}
        </button>
    );
};

CustomButton.defaultProps = {
    type: 'submit',
    disabled: false,
    className: 'text-15px md:text-lg',
    theme: 'secondary',
};

export default React.memo(CustomButton);
