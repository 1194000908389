import { ContextStatic, ModelJson } from "@devslane/mobx-entity-manager";
import { CarType } from "../../../enums/CarType";
import { InstaCleaningPlanType } from "../../../enums/InstaCleaningPlanType";

class CleaningPartnerService {
  static getInstance(): CleaningPartnerService {
    return new CleaningPartnerService();
  }

  async listCleaningPartners(data: {
    cleaning_building_id: number;
    start_time: string;
    start_date: string;
    end_time: string;
    end_date: string;
  }): Promise<{ cleaning_partners: ModelJson[] }> {
    return await ContextStatic.default.apiService
      .get("/cleaning-partners", {
        params: {
          cleaning_building_id: data.cleaning_building_id.toString(),
          start_time: data.start_time,
          start_date: data.start_date,
          end_date: data.end_date,
          end_time: data.end_time,
        },
        extras: {
          useAuth: false,
        },
      })
      .then((res: any) => {
        return {
          // partners: BookingPartnerModel.fromJson({ ...(res?.partners || []) }) as unknown as BookingPartnerModel[],
          cleaning_partners: res.cleaning_partners || [],
        };
      });
  }

  async listCleaningBuildings(): Promise<{ cleaning_buildings: ModelJson[] }> {
    return await ContextStatic.default.apiService
      .get("/admin/building", {
        extras: {
          useAuth: false,
        },
      })
      .then((res: any) => {
        return {
          // partners: BookingPartnerModel.fromJson({ ...(res?.partners || []) }) as unknown as BookingPartnerModel[],
          cleaning_buildings: res.data || [],
        };
      });
  }

  async listTimeSlotForCleaningBuilding(
    id: number,
    startDate: string,
    endDate: string
  ): Promise<any> {
    return await ContextStatic.default.apiService
      .get("/cleaning-building/cleaning-slot", {
        params: {
          cleaning_building_id: id + "",
          start_date: startDate,
          end_date: endDate,
        },
        extras: {
          useAuth: false,
        },
      })
      .then((res: any) => {
        return {
          time_slot: res.cleaning_building_slot,
        };
      });
  }
}

export const cleaningPartnerService = CleaningPartnerService.getInstance();
