import { action, observable } from 'mobx';
import { ChildStore } from '../ChildStore';
import { RootStore } from '../RootStore';
import { BookingPartnerModel } from "../models/BookingPartnerModel";
import { PaginatedModelList } from "@devslane/mobx-entity-manager";
import { bookingPartnerService } from "../services/api-services/BookingPartnerService";
import { CarType } from "../../enums/CarType";
import { ServiceType } from "../../enums/ServiceType";

export class BookingPartnerStore extends ChildStore<BookingPartnerModel> {
    constructor(rootStore: RootStore) {
        super(rootStore);
        BookingPartnerModel._store = this;
    }

    @observable
    bookingPartnersList: PaginatedModelList<BookingPartnerModel> = new PaginatedModelList<BookingPartnerModel>(BookingPartnerModel);

    @action
    async list(data: { date: string, car_type: CarType, service_type: ServiceType }): Promise<void> {
        try {
            // await this.bookingPartnersList.load('/partners', { ...data });
            
            await bookingPartnerService.list(data).then((res) => {
                this.bookingPartnersList.deserialize(res.partners);
            });
        } catch (e) {
            
            throw e;
        }
    }

    reset(): void {

        this.entities.clear();
    }
}
