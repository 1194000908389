import { ApiOptionsConfig, ApiService, ErrorCode, HeadersType, QueryParams } from '@devslane/mobx-entity-manager';
import Axios, { AxiosRequestConfig, CancelToken, CancelTokenSource } from 'axios';
import { set } from 'lodash';
import { Utils } from '../../utils/Utils';
import { Context } from '../Context';

interface RequestConfig extends AxiosRequestConfig {
    requestId?: string;
    redirectIfUnauthorized?: boolean;
}

export type Dictionary<T> = { [key: string]: T };

export class BaseApiService implements ApiService {
    private static instance: BaseApiService;

    private requestMap = new Map<string, CancelTokenSource>();

    public static getInstance(): BaseApiService {
        if (!this.instance) {
            this.instance = new BaseApiService();
        }

        return this.instance;
    }

    head<T>(url: string, opts?: ApiOptionsConfig): Promise<T> {
       
        throw new Error('head method not implemented in BaseApiService.');
    }

    public get<T = any>(
        url: string,
        opts?: {
            params?: QueryParams;
            headers?: HeadersType;
            extras: {
                requestId?: string;
                useAuth?: boolean;
            };
        },
    ): Promise<T> {
        return this.request<T>(
            { method: 'GET', url, headers: opts?.headers, params: opts?.params, requestId: opts?.extras?.requestId },
            opts?.extras?.useAuth,
        );
    }

    public delete<T = any>(
        url: string,
        opts: {
            params?: QueryParams;
            headers?: HeadersType;
            extras: {
                requestId?: string;
                useAuth?: boolean;
            };
        },
    ): Promise<T> {
        return this.request<T>(
            { method: 'DELETE', url, headers: opts?.headers, params: opts?.params, requestId: opts?.extras?.requestId },
            opts?.extras?.useAuth,
        );
    }

    public post<T = any>(
        url: string,
        data?: any,
        opts?: {
            headers?: HeadersType;
            params?: QueryParams;
            extras: {
                requestId?: string;
                useAuth?: boolean;
            };
        },
    ): Promise<T> {
        return this.request<T>(
            {
                method: 'POST',
                url,
                data,
                headers: opts?.headers,
                params: opts?.params,
                requestId: opts?.extras?.requestId,
            },
            opts?.extras?.useAuth,
        );
    }

    public put<T = any>(
        url: string,
        data?: any,
        opts?: {
            headers?: HeadersType;
            params?: QueryParams;
            extras?: {
                requestId?: string;
                useAuth?: boolean;
            };
        },
    ): Promise<T> {
        return this.request<T>(
            {
                method: 'PUT',
                url,
                data,
                headers: opts?.headers,
                params: opts?.params,
                requestId: opts?.extras?.requestId,
            },
            opts?.extras?.useAuth,
        );
    }

    public async patch<T = any>(
        url: string,
        data?: any,
        opts?: {
            headers?: HeadersType;
            params?: QueryParams;
            extras?: {
                requestId?: string;
                useAuth: boolean;
            };
        },
    ): Promise<T> {
        return this.request<T>(
            {
                method: 'PATCH',
                url,
                data,
                headers: opts?.headers,
                params: opts?.params,
                requestId: opts?.extras?.requestId,
            },
            opts?.extras?.useAuth,
        );
    }

    generateHeaders = async (headers: Dictionary<string>, useAuth?: boolean) => {
        let defaultHeaders = {};
        const token = await Context.storage.getAuthToken();

        if (useAuth && (token || '').length > 0) {
            defaultHeaders = {
                ...defaultHeaders,
                Authorization: `bearer ${token}`,
            };
        }

        if (!headers) {
            return defaultHeaders;
        }
        return { ...defaultHeaders, ...headers };
    };

    public generateRequestId(): string {
        return Utils.getRandomString();
    }

    public cancelRequest(requestId: string) {
        const source = this.requestMap.get(requestId);
        source && source.cancel();
    }

    // TODO: pass token only when required.
    private async request<T>(config: RequestConfig, useAuth?: boolean): Promise<T> {
        const cancelToken = this.addToRequestMap(config.requestId);
        try {
            const response = await Axios.request({
                baseURL: Context.baseUrl,
                cancelToken,
                ...config,
                headers: await this.generateHeaders(config.headers, useAuth ?? true),
            });
            this.removeFromRequestMap(config.requestId);
            return response?.data as T;
        } catch (error: any) {
            const _error = error;

            if (typeof document !== 'undefined') {
                const evt = new CustomEvent('globalAxiosError', { detail: error });

                document?.dispatchEvent(evt);
            }

            //there will be no response when there is connection issue
            if (error.response === undefined) {
                set(_error, ['response', 'status'], -1);
            }

            const errorStatus = _error?.response?.status;

            if (errorStatus === ErrorCode.UNAUTHORIZED) {
                await Context.storage.reset();
            }

            throw _error;
        }
    }

    private addToRequestMap(requestId?: string): CancelToken | undefined {
        if (!requestId) {
            return undefined;
        }

        const source = Axios.CancelToken.source();
        this.requestMap.set(requestId, source);
        return source.token;
    }

    private removeFromRequestMap(requestId?: string) {
        if (!requestId) {
            return;
        }

        this.requestMap.delete(requestId);
    }
}
