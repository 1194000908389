import { action, observable } from "mobx";
import { ChildStore } from "../ChildStore";
import { RootStore } from "../RootStore";
import { ModelList } from "@devslane/mobx-entity-manager";
import { CarTypeModel } from "../models/CarTypeModel";

export class CarTypeStore extends ChildStore<CarTypeModel> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    CarTypeModel._store = this;
  }

  @observable
  carModelList: ModelList<CarTypeModel> = new ModelList<CarTypeModel>(
    CarTypeModel
  );

  @action
  async getCarModels(carDetail: { car_brand_id: string }): Promise<void> {
    try {
      this.carModelList.clearItems();
      const { car_brand_id } = carDetail;
      await this.carModelList.load(
        "/bookings/car-models",
        { car_brand_id },
        {
          dataKey: "car_models",
        }
      );
    } catch (e) {
      throw e;
    }
  }

  reset(): void {
    this.entities.clear();
  }
}
