import { EntityBaseModel } from '@devslane/mobx-entity-manager';
import { observable } from 'mobx';
import { UserStore } from '../entities/UserStore';

export class UserModel extends EntityBaseModel {
    static _store: UserStore;

    @observable
    email!: string;

    @observable
    first_name!: string;

    @observable
    middle_name!: string;

    @observable
    last_name!: string;

    @observable
    mobile_number!: string;

    @observable
    status!: string;
}
