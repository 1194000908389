import { ContextStatic } from "@devslane/mobx-entity-manager";

class BuildingPackageService {
  static getInstance(): BuildingPackageService {
    return new BuildingPackageService();
  }

  async listBuildingPackage(
    buildingId: any,
    carTypeId: any
  ): Promise<{ building_package: any }> {
    return await ContextStatic.default.apiService
      .get(`/admin/building/package/${buildingId}/${carTypeId}`, {
        extras: {
          useAuth: false,
        },
      })
      .then((res: any) => {
        return {
          building_package: res.data,
        };
      });
  }

  async checkUserPackageAvailLimit(data: {
    cleaning_building_package_id: number;
    avail_limit: number;
  }): Promise<any> {
    return await ContextStatic.default.apiService.post(
      "/check/package/limit",
      { ...data },
      {
        extras: { useAuth: true },
      }
    );
  }
}

export const buildingPackageService = BuildingPackageService.getInstance();
