import { EntityBaseModel } from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { CleaningBuildingPackageStore } from "../entities/CleaningBuildingPackage";
export class CleaningBuildingPackageModel extends EntityBaseModel {
  static _store: CleaningBuildingPackageStore;

  @observable
  package!: any;

  @observable
  building!: any;
}
