import { ContextStatic, ModelJson } from '@devslane/mobx-entity-manager';
import { CarType } from "../../../enums/CarType";
import { ServiceType } from "../../../enums/ServiceType";
import { format } from "date-fns";
import { BookingListType } from "../../../enums/BookingListType";

class PartnerBookingService {
    static getInstance(): PartnerBookingService {
        return new PartnerBookingService();
    }

    async listBookings(filters: { service_type: ServiceType, booking_list_type: BookingListType}): Promise<{ bookings: ModelJson[] }> {
        return await ContextStatic.default.apiService
            .get('/bookings', {
                params: filters
            })
            .then((res: any) => {
                return {
                    // partners: BookingPartnerModel.fromJson({ ...(res?.partners || []) }) as unknown as BookingPartnerModel[],
                    bookings: res.bookings,
                };
            });
    }

    async createBooking(data: { partner_id: number, car_type: CarType, date: string, start_time: string, end_time: string, car_number: string, washing_package_id?: number, detailing_package_id?: number }): Promise<{ booking: ModelJson }> {
        return await ContextStatic.default.apiService
            .post('/bookings', data,{
                extras: {
                    useAuth: true
                }
            })
            .then((res: any) => {
                return {
                    booking: res.booking
                };
            });
    }
}

export const partnerBookingService = PartnerBookingService.getInstance();
