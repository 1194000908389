import { random, times, pickBy, isArray, forEach } from 'lodash';

export class Utils {
    static getRandomString = (length = 20) => times(length, () => random(35).toString(36)).join('');

    static removeEmptyKeys = (obj: any) => {
        Object.keys(obj).forEach((key) => (obj[key] === '' ) && delete obj[key]);//|| obj[key] === []
    };

    static sanitizeObject = (data: any) => {
        return pickBy(data, (v: any) => (isArray(v) ? !!v.length : v !== null && v !== undefined && v !== ''));
    };

    static convetToFormData = (data: any) => {
        const formData = new FormData();
        forEach(Object.keys(Utils.sanitizeObject(data || {}) || {}), (key) => {
            formData.append(key, data[key]);
        });
        return formData;
    };

    static getUniqueId = () => (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();

    static iterateEnum<T>(enumRef: any): T[] {
        return Object.keys(enumRef).map(key => enumRef[key]);
    }
}
