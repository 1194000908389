import React, { Suspense } from "react";
import TopRoundedSpinner from "../spinner/TopRoundedSpinner";

const LazyWithSuspense = (WrappedComponent: React.ComponentType) => {
  return class extends React.Component {
    render() {
      return (
        <Suspense
          fallback={
            <div
              className={
                "h-screen w-screen z-50 flex justify-center items-center"
              }
            >
              <TopRoundedSpinner size={"md"} />
            </div>
          }
        >
          <WrappedComponent {...this.props} />
        </Suspense>
      );
    }
  };
};

export default LazyWithSuspense;
