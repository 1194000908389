import { action, observable } from "mobx";
import { ChildStore } from "../ChildStore";
import { RootStore } from "../RootStore";
import { ModelList } from "@devslane/mobx-entity-manager";
import { CarBrandModel } from "../models/CarBrandModel";

export class CarBrandStore extends ChildStore<CarBrandModel> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    CarBrandModel._store = this;
  }

  @observable
  carBrandList: ModelList<CarBrandModel> = new ModelList<CarBrandModel>(
    CarBrandModel
  );

  @action
  async getCarBrands(): Promise<void> {
    try {
      this.carBrandList.clearItems();
      await this.carBrandList.load(
        "/bookings/car-brands",
        {},
        {
          dataKey: "car_brands",
        }
      );
    } catch (e) {
      throw e;
    }
  }
  reset(): void {
    this.entities.clear();
  }
}
