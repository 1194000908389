import { action, observable } from "mobx";
import { ChildStore } from "../ChildStore";
import { RootStore } from "../RootStore";
import { BookingPartnerModel } from "../models/BookingPartnerModel";
import { ModelItem, ModelList } from "@devslane/mobx-entity-manager";
import { bookingPartnerService } from "../services/api-services/BookingPartnerService";
import { CarType } from "../../enums/CarType";
import { PartnerBookingModel } from "../models/PartnerBookingModel";
import { ServiceType } from "../../enums/ServiceType";
import { BookingListType } from "../../enums/BookingListType";
import { partnerBookingService } from "../services/api-services/PartnerBookingService";

export class PartnerBookingStore extends ChildStore<BookingPartnerModel> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    PartnerBookingModel._store = this;
  }

  @observable
  booking: ModelItem<PartnerBookingModel> = new ModelItem<PartnerBookingModel>(
    PartnerBookingModel
  );

  @observable
  bookingDetail: ModelItem<PartnerBookingModel> = new ModelItem<PartnerBookingModel>(
    PartnerBookingModel
  );

  @observable
  washingTodayBookingsList: ModelList<PartnerBookingModel> = new ModelList<PartnerBookingModel>(
    PartnerBookingModel
  );

  @observable
  washingUpcomingBookingsList: ModelList<PartnerBookingModel> = new ModelList<PartnerBookingModel>(
    PartnerBookingModel
  );

  @observable
  washingCompletedBookingsList: ModelList<PartnerBookingModel> = new ModelList<PartnerBookingModel>(
    PartnerBookingModel
  );

  @observable
  detailingTodayBookingsList: ModelList<PartnerBookingModel> = new ModelList<PartnerBookingModel>(
    PartnerBookingModel
  );

  @observable
  detailingUpcomingBookingsList: ModelList<PartnerBookingModel> = new ModelList<PartnerBookingModel>(
    PartnerBookingModel
  );

  @observable
  detailingCompletedBookingsList: ModelList<PartnerBookingModel> = new ModelList<PartnerBookingModel>(
    PartnerBookingModel
  );

  @observable
  serviceType!: ServiceType;

  @observable
  carType!: CarType;

  @observable
  vehicleNumber!: string;

  @observable
  booking_date!: string;

  @observable
  selected_partner_id!: number;

  @observable
  selected_package_id!: number;

  @observable
  selected_slot!: string;

  @action
  async listBookings(filters: {
    service_type: ServiceType;
    booking_list_type: BookingListType;
  }): Promise<void> {
    try {
      const bookings = (await partnerBookingService.listBookings(filters))
        .bookings;
      // if (filters.booking_list_type === BookingListType.TODAY && filters.service_type === ServiceType.CAR_WASHING) {
      //     this.washingTodayBookingsList.setItems(bookings.map(booking => PartnerBookingModel.fromJson(booking) as PartnerBookingModel));
      // }

      // if (filters.booking_list_type === BookingListType.TODAY && filters.service_type === ServiceType.CAR_DETAILING) {
      //     this.detailingTodayBookingsList.setItems(bookings.map(booking => PartnerBookingModel.fromJson(booking) as PartnerBookingModel));
      // }

      if (
        filters.booking_list_type === BookingListType.UPCOMING &&
        filters.service_type === ServiceType.CAR_WASHING
      ) {
        this.washingUpcomingBookingsList.setItems(
          bookings.map(
            (booking) =>
              PartnerBookingModel.fromJson(booking) as PartnerBookingModel
          )
        );
      }

      if (
        filters.booking_list_type === BookingListType.UPCOMING &&
        filters.service_type === ServiceType.CAR_DETAILING
      ) {
        this.detailingUpcomingBookingsList.setItems(
          bookings.map(
            (booking) =>
              PartnerBookingModel.fromJson(booking) as PartnerBookingModel
          )
        );
      }

      if (
        filters.booking_list_type === BookingListType.COMPLETED &&
        filters.service_type === ServiceType.CAR_WASHING
      ) {
        this.washingCompletedBookingsList.setItems(
          bookings.map(
            (booking) =>
              PartnerBookingModel.fromJson(booking) as PartnerBookingModel
          )
        );
      }

      if (
        filters.booking_list_type === BookingListType.COMPLETED &&
        filters.service_type === ServiceType.CAR_DETAILING
      ) {
        this.detailingCompletedBookingsList.setItems(
          bookings.map(
            (booking) =>
              PartnerBookingModel.fromJson(booking) as PartnerBookingModel
          )
        );
      }
    } catch (e) {
      throw e;
    }
  }

  @action
  async getById(data: { id: number }): Promise<void> {
    try {
      const booking = (await bookingPartnerService.getById(data)).booking;
      this.bookingDetail.setItem(
        PartnerBookingModel.fromJson(booking) as PartnerBookingModel
      );
    } catch (e) {
      throw e;
    }
  }

  @action
  async createBooking(data: {
    partner_id: number;
    car_type: CarType;
    start_time: string;
    end_time: string;
    car_number: string;
    washing_package_id?: number;
    detailing_package_id?: number;
  }): Promise<void> {
    try {
      const booking = (await bookingPartnerService.createBooking(data)).booking;
      
      this.booking.setItem(
        PartnerBookingModel.fromJson(booking) as PartnerBookingModel
      );
    } catch (e) {
      throw e;
    }
  }

  reset(): void {
    this.entities.clear();
  }
}
