import { ContextStatic, ModelJson } from "@devslane/mobx-entity-manager";

class CleaningBookingService {
  static getInstance(): CleaningBookingService {
    return new CleaningBookingService();
  }

  async listBookings(): Promise<{ cleaning_bookings: ModelJson[] }> {
    return await ContextStatic.default.apiService
      .get("/cleaning-bookings")
      .then((res: any) => {
        return {
          // partners: BookingPartnerModel.fromJson({ ...(res?.partners || []) }) as unknown as BookingPartnerModel[],
          cleaning_bookings: res.cleaning_bookings,
        };
      });
  }

  async getById(data: {
    id: number;
  }): Promise<{ cleaning_booking: ModelJson }> {
    return await ContextStatic.default.apiService
      .get(`/cleaning-bookings/${data.id}`)
      .then((res: any) => {
        return {
          cleaning_booking: res.cleaning_booking,
        };
      });
  }

  async createBooking(data: {
    cleaning_building_id: number;
    cleaning_partner_id: number;
    cleaning_building_package_id: number;
    start_date: string;
    end_date: string;
    start_time: string;
    end_time: string;
    car_number: string;
    flat_detail: string;
    parking_number: string;
    car_type_id: number;
    car_model_id: number;
    car_brand_id: number;
    cost_price: number;
    selling_price: number;
    discount: number;
    gst: number;
    final_price: number;
    payment_order_id?: string;
  }): Promise<{ booking: ModelJson }> {
    return await ContextStatic.default.apiService
      .post("/cleaning-bookings", data, {
        extras: {
          useAuth: true,
        },
      })
      .then((res: any) => {
        return {
          booking: res.booking,
        };
      });
  }

  async updateBooking(data: any, id: string): Promise<{ booking: ModelJson }> {
    return await ContextStatic.default.apiService
      .put("/cleaning-bookings/" + id, data, {
        extras: {
          useAuth: true,
        },
      })
      .then((res: any) => {
        return {
          booking: res.booking,
        };
      });
  }

  async validateBooking(data: {
    cleaning_building_id: number;
    cleaning_partner_id: number;
    cleaning_building_package_id: number;
    start_date: string;
    end_date: string;
    start_time: string;
    end_time: string;
    car_number: string;
    car_type_id: number;
    car_model_id: number;
    car_brand_id: number;
  }): Promise<{ booking: ModelJson }> {
    return await ContextStatic.default.apiService
      .post("/validate/cleaning-bookings", data, {
        extras: {
          useAuth: true,
        },
      })
      .then((res: any) => {
        return {
          booking: res.booking,
        };
      });
  }
}

export const cleaningBookingService = CleaningBookingService.getInstance();
