import { EntityBaseModel } from '@devslane/mobx-entity-manager';
import { observable } from 'mobx';
import { BookingPartnerStore } from "../entities/BookingPartnerStore";
import { CleaningPartnerStore } from "../entities/CleaningPartnerStore";

export class CleaningPartnerModel extends EntityBaseModel {
    static _store: CleaningPartnerStore;

    @observable
    name!: string;

    @observable
    email!: string;

    @observable
    address_line_1!: string;

    @observable
    city!: string;

    @observable
    state!: string;

    @observable
    pin_code!: string;

    @observable
    country!: string;

    @observable
    latitude!: string;

    @observable
    longitude!: string;

    @observable
    mobile_number!: string;

    @observable
    rating!: string;

    @observable
    profile_pic_url!: string;
}
