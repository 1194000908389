export const AUTH_TOKEN = "auth_token";

export class StorageService {
  private static _instance: StorageService;

  private constructor() {}

  static getInstance(): StorageService {
    if (!this._instance) {
      this._instance = new StorageService();
    }

    return this._instance;
  }

  setValue(key: string, value: string): void {
    let date = new Date();
    date.setDate(date.getDate() + 1);
   
    document.cookie = `${key}=${value};`+"path=/" + ";expires=" + date;
  }

  getValue(key: string): string | null {
    if(document.cookie!=undefined)
    {

    
    let map = new Map();
    const cookieArray: Array<Array<string>> = document.cookie.split(";").map((item) =>{
        return item.split("=");
      });
     
    cookieArray.forEach((item, Index) => {
      item.forEach((item, index) => {
        if (item === `${key}`) {
          map.set(key, cookieArray[Index][index + 1]);
        }
      });
    });
    return map.get(key);
}
    return null
  }

  removeValue(key: string): void {
    let date = new Date();
    date.setDate(date.getDate()-1);
    document.cookie =`${key}=; path=/ `+";expires=" + date;
  }

  async setAuthToken(token: string): Promise<void> {
    this.setValue(AUTH_TOKEN, token);
  }

  async getAuthToken(): Promise<string | null> {
    return this.getValue(AUTH_TOKEN);
  }

  async removeAuthToken(): Promise<void> {
    this.removeValue(AUTH_TOKEN);
  }

  async reset(): Promise<void> {
    this.removeValue(AUTH_TOKEN);
  }
}
