import { action, observable } from "mobx";
import { ChildStore } from "../ChildStore";
import { RootStore } from "../RootStore";
import { ModelList } from "@devslane/mobx-entity-manager";
import { CarTypeModel } from "../models/CarTypeModel";
import { AdminDiscountModel } from "../models/AdminDiscountModel";
import { userService } from "../services/api-services/UserService";

export class AdminDiscountStore extends ChildStore<AdminDiscountModel> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    AdminDiscountModel._store = this;
  }

  @observable
  discountCoupon: ModelList<AdminDiscountModel> = new ModelList<AdminDiscountModel>(
    AdminDiscountModel
  );

  @action
  async getAdminDiscount(): Promise<void> {
    try {
      this.discountCoupon.clearItems();
      await this.discountCoupon.load("/user/admin/discount",{
        dataKey: "data",
      });
    } catch (e) {
      throw e;
    }
  }

  @action
  async checkUserAdminDiscount(data: { discountId: number, carNumber: string }): Promise<void> {
    try {
      await userService.checkUserAdminDiscount(data).then((res) => {
        return res;
      });
    } catch (e) {
      throw e;
    }
  }

  reset(): void {
    this.entities.clear();
  }
}
