import { ToastService } from './store/services/ToastService';
import { StorageService } from "./store/services/StorageService";
import { RootStore } from "./store";

export class AppContext {
    static store: RootStore;
    static storage: StorageService;
    static toast: ToastService;

    static boot(): void {
        this.store = RootStore.getInstance();
        this.storage = StorageService.getInstance();
        this.toast = ToastService.getInstance();
    }
}
