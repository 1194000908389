import { EntityBaseModel } from '@devslane/mobx-entity-manager';
import { observable } from 'mobx';
import { BookingPartnerStore } from "../entities/BookingPartnerStore";

export class BookingPartnerModel extends EntityBaseModel {
    static _store: BookingPartnerStore;

    @observable
    name!: string;

    @observable
    email!: string;

    @observable
    address_line_1!: string;

    @observable
    city!: string;

    @observable
    state!: string;

    @observable
    pin_code!: string;

    @observable
    country!: string;

    @observable
    latitude!: string;

    @observable
    longitude!: string;

    @observable
    mobile_number!: string;

    @observable
    start_time!: string;

    @observable
    end_time!: string;

    @observable
    car_washing!: string;

    @observable
    car_detailing!: string;

    @observable
    car_washing_bays?: number;

    @observable
    car_detailing_bays?: number;

    @observable
    rating!: string;

    @observable
    meta!: string;

    @observable
    profile_pic_url!: string;

    @observable
    distance?: string;
}
