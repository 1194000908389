import { EntityBaseModel } from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { BookingPartnerStore } from "../entities/BookingPartnerStore";
import { BookingPartnerPackageStore } from "../entities/BookingPartnerPackageStore";

export class BookingPartnerPackageModel extends EntityBaseModel {
  static _store: BookingPartnerPackageStore;

  @observable
  partner_id!: string;

  @observable
  name!: string;

  @observable
  description!: string;

  @observable
  hatchback_price!: string;

  @observable
  suv_price!: string;

  @observable
  sedan_price!: string;

  @observable
  "luxury/sports_price"!: string;

  @observable
  duration!: number;

  @observable
  slot_availability!: { start_time: string; end_time: string }[];
}
