import { action, observable } from "mobx";
import { ChildStore } from "../ChildStore";
import { RootStore } from "../RootStore";
import { ModelList } from "@devslane/mobx-entity-manager";
import { cleaningPartnerService } from "../services/api-services/CleaningPartnerService";
import { CleaningBuildingModel } from "../models/CleaningBuildingModel";
import { CleaningBuildingPackageModel } from "../models/CleaningBuildingBooking";
import { buildingPackageService } from "../services/api-services/BuildingPackageService";

export class CleaningBuildingPackageStore extends ChildStore<CleaningBuildingPackageModel> {
  [x: string]: any;
  constructor(rootStore: RootStore) {
    super(rootStore);
    CleaningBuildingPackageModel._store = this;
  }

  @observable
  buildingPackage: ModelList<CleaningBuildingPackageModel> = new ModelList<CleaningBuildingPackageModel>(
    CleaningBuildingModel
  );

  @action
  async listBuildingPackage(buildingId: any, carTypeId: any): Promise<void> {
    try {
      await buildingPackageService
        .listBuildingPackage(buildingId, carTypeId)
        .then((res) => {
          this.buildingPackage.deserialize(res.building_package);
        });
    } catch (e) {
      throw e;
    }
  }
  @action
  async checkUserPackageAvailLimit(data: {
    car_number: string;
    cleaning_building_package_id: number;
    avail_limit: number;
  }): Promise<void> {
    try {
      await buildingPackageService
        .checkUserPackageAvailLimit(data)
        .then((res) => {
          return res;
        });
    } catch (e) {
      throw e;
    }
  }

  reset(): void {
    this.entities.clear();
  }
}
