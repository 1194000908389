import { action, computed, observable } from 'mobx';
import { ChildStore } from '../ChildStore';
import { Context } from '../Context';
import { UserModel } from '../models/UserModel';
import { PartnerRequestModel} from '../models/PartnerRequestModel';
import { RootStore } from '../RootStore';
import { userService } from '../services/api-services/UserService';
import { ModelJson } from "@devslane/mobx-entity-manager";
import { AppContext } from "../../App.context";
import { PartnerTypes } from '../../enums/PartnerTypes';

export enum LoginStatus {
    LOGGED_IN,
    LOGGED_OUT,
    PENDING,
}

export class UserStore extends ChildStore<UserModel> {
    constructor(rootStore: RootStore) {
        super(rootStore);
        UserModel._store = this;
    }

    @observable loginStatus: LoginStatus = LoginStatus.PENDING;
    @observable loggedInUser?: ModelJson;
    @observable signedUpUser?: UserModel;

    @observable
    latitude!: string;

    @observable
    longitude!: string;

    lastPath!: string;

    resetPasswordEmail!: string | null;

    @computed get isLoggedIn(): boolean {
        return this.loginStatus === LoginStatus.LOGGED_IN;
    }

    hasLoggedInToken = async () => {
        return !!(await Context.storage.getAuthToken());
    };

    getLoggedInToken = async (): Promise<string | null> => {
        return await Context.storage.getAuthToken();
    };

    @action
    async setLastPath(path: string): Promise<string> {
        if (path.includes('forgot-password') || path.includes('code-confirmation') || path.includes('reset-password') || path.includes('/sign-up') || path.includes('/login')) {
           
            return this.lastPath;
        }
     
        this.lastPath = path;
        return this.lastPath;
    }

    @action
    async me(): Promise<UserModel> {
        try {
            await this.setLoginStatus(LoginStatus.PENDING);
            const user = await userService.me();
         
            await AppContext.store.userAddressStore.listUserAddresses();
            this.setLoggedInUser(user);
            return user;
        } catch (e) {
            await Context.storage.removeAuthToken();
            this.setLoginStatus(LoginStatus.LOGGED_OUT);
          
            throw e;
        }
    }

    @action
    async login(data: { email: string, password: string }): Promise<UserModel> {
        return await userService.login(data).then((res) => {
            Context.storage.setAuthToken(res.token);
            this.setLoggedInUser(res.user);
            return res.user;
        });
    }

    @action
    async sendLoginOtp(data: { mobileNumber: string}): Promise<UserModel> {
        return await userService.sendLoginOtp(data).then((res) => {
            return res.user;
        });
    }

    @action
    async verifyLoginOtp(data: { mobileNumber: string, otp: string }): Promise<UserModel> {
        return await userService.verifyLoginOtp(data).then((res) => {
            
            Context.storage.setAuthToken(res.token);
            this.setLoggedInUser(res.user);
            return res.user;
        });
    }

    @action
    async signUp(data: { first_name: string, middle_name?: string, last_name: string, email: string; password: string, mobile_number: string }): Promise<UserModel> {
        return await userService.signUp(data).then((res) => {
            // this.setSignedUpUser(res.user);
            return res.user;
        });
    }

    @action
    async forgotPassword(data: { email: string }): Promise<{ success: boolean }> {
        return await userService.forgotPassword(data).then((res) => {
            this.resetPasswordEmail = data.email;
            return {
                success: res.success
            };
        });
    }

    @action
    async resendResetPasswordCode(data: { email: string }): Promise<{ success: boolean }> {
        return await userService.resendResetPasswordCode(data).then((res) => {
            return {
                success: res.success
            };
        });
    }

    @action
    async resetPassword(data: { code: string, password: string }): Promise<{ success: boolean }> {
        return await userService.resetPassword(data).then((res) => {
            this.resetPasswordEmail = null;
            return {
                success: res.success
            };
        });
    }

    @action
    async codeConfirmation(data: { email: string, code: string }): Promise<UserModel> {
        return await userService.codeConfirmation(data).then((res) => {
            Context.storage.setAuthToken(res.token);
            this.removeSignedUpUser();
            this.setLoggedInUser(res.user);
            return res.user;
        });
    }

    @action
    async resendCodeConfirmation(data: { email: string }): Promise<{ success: boolean }> {
        return await userService.resendCodeConfirmation(data).then((res) => {
            return {
                success: res.success
            };
        });
    }

    @action
    async updateMeCode(): Promise<void> {
        await userService.updateMeCode().then((res) => {
        });
    }

    @action
    async changePasswordCode(): Promise<void> {
        await userService.changePasswordCode().then((res) => {
        });
    }

    @action
    async changePassword(data: { password: string, code?: string }): Promise<void> {
        await userService.changePassword(data).then((res) => {
        });
    }

    @action
    async updateMe(data: { first_name: string, middle_name?: string, last_name: string, email: string; mobile_number: string, code?: string }): Promise<UserModel> {
        return await userService.updateMe(data).then((res) => {
            this.setLoggedInUser(res.user);
            return res.user;
        });
    }

    @action
    setLoggedInUser(data: ModelJson): void {
        
        this.loggedInUser = data;
        this.setLoginStatus(LoginStatus.LOGGED_IN);
        
    }

    @action
    setSignedUpUser(data: UserModel): void {
        this.signedUpUser = UserModel.fromJson(data) as UserModel;
    }

    @action
    removeSignedUpUser(): void {
        this.signedUpUser = undefined;
    }

    @action
    setLoginStatus(status: LoginStatus): void {
        this.loginStatus = status;

        if (status === LoginStatus.LOGGED_OUT) {
            this.loggedInUser = undefined;
            Context.storage.reset();
        }
    }

    @action
    async submitPartnerRequest(data: {  
        firstName: string;
        middleName: string;
        lastName: string;
        email: string;
        address: string;
        mobileNumber: string;
        studioName: string;
        gstin: string;
        partnerType: PartnerTypes;
        question: string;
        }): Promise<PartnerRequestModel> {
        return await userService.createPartnerRequest(data).then((res) => {
            return res.user;
        });
    }

    reset(): void {
        this.setLoginStatus(LoginStatus.LOGGED_OUT);
        this.loggedInUser = undefined;
        this.entities.clear();
        Context.storage.reset();
    }
}
