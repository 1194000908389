import { action, observable } from "mobx";
import { ChildStore } from "../ChildStore";
import { RootStore } from "../RootStore";
import {
  ModelItem,
  ModelList,
  PaginatedModelList,
} from "@devslane/mobx-entity-manager";
import { BookingPartnerPackageModel } from "../models/BookingPartnerPackageModel";
import { bookingPartnerService } from "../services/api-services/BookingPartnerService";
import { CarType } from "../../enums/CarType";
import { ServiceType } from "../../enums/ServiceType";
import { PartnerBookingModel } from "../models/PartnerBookingModel";
import { CleaningPartnerModel } from "../models/CleaningPartnerModel";
import { CleaningBookingModel } from "../models/CleaningBookingModel";
import { InstaCleaningPlanType } from "../../enums/InstaCleaningPlanType";
import { cleaningPartnerService } from "../services/api-services/CleaningPartnerService";
import { CleaningBuildingModel } from "../models/CleaningBuildingModel";

export class CleaningPartnerStore extends ChildStore<CleaningPartnerModel> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    CleaningPartnerModel._store = this;
    // CleaningBuildingModel._store = this;
  }

  @observable
  cleaningPartnersList: PaginatedModelList<CleaningPartnerModel> = new PaginatedModelList<CleaningPartnerModel>(
    CleaningPartnerModel
  );
  //
  // @observable
  // cleaningBuildingsList: PaginatedModelList<CleaningBuildingModel> = new PaginatedModelList<CleaningBuildingModel>(CleaningBuildingModel);

  // @observable
  // serviceType!: ServiceType;
  //
  // @observable
  // carType!: CarType;
  //
  // @observable
  // vehicleNumber!: string;
  //
  // @observable
  // startDate!: string;
  //
  // @observable
  // endDate!: string;
  //
  // @observable
  // startTime!: string;
  //
  // @observable
  // endTime!: string;
  //
  // @observable
  // selectedBuildingId!: number;
  //
  // @observable
  // selectedPlanType!: InstaCleaningPlanType;
  //
  // @observable
  // selectedPlanPrice!: string;
  //
  // @observable
  // selectedPartnerId!: number;

  @action
  async listCleaningPartners(data: {
    cleaning_building_id: number;
    start_time: string;
    end_time: string;
    start_date: string;
    end_date: string;
  }): Promise<void> {
    try {
      await cleaningPartnerService.listCleaningPartners(data).then((res) => {
        this.cleaningPartnersList.deserialize(res.cleaning_partners);
      });
    } catch (e) {
      
      throw e;
    }
  }

  // @action
  // async listCleaningBuildings(): Promise<void> {
  //     try {
  //         await cleaningPartnerService.listCleaningBuildings().then((res) => {
  //            
  //             this.cleaningBuildingsList.deserialize(res.cleaning_buildings);
  //            
  //         });
  //     } catch (e) {
  //         
  //         throw e;
  //     }
  // }

  // @action
  // async createBooking(data: { partner_id: number, car_type: CarType, date: string, start_time: string, end_time: string, car_number: string, washing_package_id?: number, detailing_package_id?: number }): Promise<void> {
  //     try {
  //         const booking = (await bookingPartnerService.createBooking(data)).booking;
  
  //         this.booking.setItem(PartnerBookingModel.fromJson(booking) as PartnerBookingModel);
  //     } catch (e) {
  //         throw e;
  //     }
  // }

  reset(): void {
    this.entities.clear();
  }
}
