import { EntityBaseModel } from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { CarBrandStore } from "../entities/CarBrandStore";

export class CarBrandModel extends EntityBaseModel {
  static _store: CarBrandStore;

  @observable
  name?: string;
}
