import { ContextStatic, ModelJson } from "@devslane/mobx-entity-manager";
import { UserModel } from "../../models/UserModel";
import { UserAddressModel } from "../../models/UserAddressModel";
import { PartnerRequestModel } from "../../models/PartnerRequestModel";
import { PartnerTypes } from "../../../enums/PartnerTypes";

class UserService {
  static getInstance(): UserService {
    return new UserService();
  }

  async me(): Promise<UserModel> {
    return await ContextStatic.default.apiService
      .get("/me")
      .then((res: any) => {
        const me = res?.user || {};
        // return UserModel.fromJson({ ...me }) as UserModel;
        return me;
      });
  }

  async login(data: {
    email: string;
    password: string;
  }): Promise<{ user: UserModel; token: string }> {
    return await ContextStatic.default.apiService
      .post("/login", data, {
        extras: { useAuth: false },
      })
      .then((res: any) => {
        return {
          user: res?.user || {},
          // user: UserModel.fromJson({ ...(res?.user || {}) }) as UserModel,
          token: res?.token || "",
        };
      });
  }

  async sendLoginOtp(data: {
    mobileNumber: string;
  }): Promise<{ user: UserModel; token: string }> {
    return await ContextStatic.default.apiService
      .post(
        "/send/user/login/otp",
        { mobile_number: data.mobileNumber },
        {
          extras: { useAuth: false },
        }
      )
      .then((res: any) => {
        return {
          user: res?.user || {},
          // user: UserModel.fromJson({ ...(res?.user || {}) }) as UserModel,
          token: res?.token || "",
        };
      });
  }

  async verifyLoginOtp(data: {
    mobileNumber: string;
    otp: string;
  }): Promise<{ user: UserModel; token: string }> {
    return await ContextStatic.default.apiService
      .post(
        "/verify/user/login/otp",
        { mobile_number: data.mobileNumber, otp: data.otp },
        {
          extras: { useAuth: false },
        }
      )
      .then((res: any) => {
        return {
          user: res?.user || {},
          // user: UserModel.fromJson({ ...(res?.user || {}) }) as UserModel,
          token: res?.token || "",
        };
      });
  }

  async listUserAddresses(): Promise<{ user_addresses: UserAddressModel[] }> {
    return await ContextStatic.default.apiService
      .get("/addresses")
      .then((res: any) => {
        return {
          user_addresses: res.user_addresses.map(
            (userAddress: ModelJson) =>
              UserAddressModel.fromJson({ ...userAddress }) as UserAddressModel
          ),
        };
      });
  }

  async signUp(data: {
    first_name: string;
    middle_name?: string;
    last_name: string;
    email: string;
    password: string;
    mobile_number: string;
  }): Promise<{ user: UserModel }> {
    return await ContextStatic.default.apiService
      .post("/sign-up", data, {
        extras: { useAuth: false },
      })
      .then((res: any) => {
        return {
          user: res?.user || {},
          // user: UserModel.fromJson({ ...(res?.user || {}) }) as UserModel
        };
      });
  }

  async forgotPassword(data: { email: string }): Promise<{ success: boolean }> {
    return await ContextStatic.default.apiService
      .post("/forgot-password", data, {
        extras: { useAuth: false },
      })
      .then((res: any) => {
        return {
          success: true,
        };
      });
  }

  async resendResetPasswordCode(data: {
    email: string;
  }): Promise<{ success: boolean }> {
    return await ContextStatic.default.apiService
      .post("/resend-reset-password-code", data, {
        extras: { useAuth: false },
      })
      .then((res: any) => {
        return {
          success: true,
        };
      });
  }

  async resetPassword(data: {
    code: string;
    password: string;
  }): Promise<{ success: boolean }> {
    return await ContextStatic.default.apiService
      .post("/reset-password", data, {
        extras: { useAuth: false },
      })
      .then((res: any) => {
        return {
          success: true,
        };
      });
  }

  async resendCodeConfirmation(data: {
    email: string;
  }): Promise<{ success: boolean }> {
    return await ContextStatic.default.apiService
      .post("/resend-confirmation-code", data, {
        extras: { useAuth: false },
      })
      .then((res: any) => {
        return {
          success: true,
        };
      });
  }

  async codeConfirmation(data: {
    email: string;
    code: string;
  }): Promise<{ token: string; user: UserModel }> {
    return await ContextStatic.default.apiService
      .post("/confirmation-code", data, {
        extras: { useAuth: false },
      })
      .then((res: any) => {
        return {
          token: res?.token || "",
          user: res?.user || {},
          // user: UserModel.fromJson({ ...(res?.user || []) }) as UserModel
        };
      });
  }

  async updateMeCode(): Promise<{ success: boolean }> {
    return await ContextStatic.default.apiService
      .get("/me/code")
      .then((res: any) => {
        return {
          success: res.success,
        };
      });
  }

  async changePasswordCode(): Promise<{ success: boolean }> {
    return await ContextStatic.default.apiService
      .get("/me/password/code")
      .then((res: any) => {
        return {
          success: res.success,
        };
      });
  }

  async changePassword(data: {
    password: string;
    code?: string;
  }): Promise<{ success: boolean }> {
    return await ContextStatic.default.apiService
      .put("/me/password", data)
      .then((res: any) => {
        return {
          success: res.success,
        };
      });
  }

  async updateMe(data: {
    first_name: string;
    middle_name?: string;
    last_name: string;
    email: string;
    mobile_number: string;
    code?: string;
  }): Promise<{ user: UserModel }> {
    return await ContextStatic.default.apiService
      .put("/me", data)
      .then((res: any) => {
        return {
          user: res?.user || {},
          // user: UserModel.fromJson({ ...(res?.user || {}) }) as UserModel
        };
      });
  }

  async addAddress(data: {
    name: string;
    address_line_1: string;
    city: string;
    state: string;
    pin_code: string;
  }): Promise<{ user_address: UserAddressModel }> {
    return await ContextStatic.default.apiService
      .post("/addresses", data)
      .then((res: any) => {
        return {
          user_address: UserAddressModel.fromJson({
            ...(res?.user_address || {}),
          }) as UserAddressModel,
        };
      });
  }

  async updateAddress(
    data: {
      name: string;
      address_line_1: string;
      city: string;
      state: string;
      pin_code: string;
    },
    id: number
  ): Promise<{ user_address: UserAddressModel }> {
    return await ContextStatic.default.apiService
      .put(`/addresses/${id}`, data)
      .then((res: any) => {
        return {
          user_address: UserAddressModel.fromJson({
            ...(res?.user_address || {}),
          }) as UserAddressModel,
        };
      });
  }

  async createPartnerRequest(data: {
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    address: string;
    mobileNumber: string;
    studioName: string;
    gstin: string;
    partnerType: PartnerTypes;
    question: string;
  }): Promise<{ user: PartnerRequestModel }> {
    return await ContextStatic.default.apiService
      .post("/user/partner/request", data, {
        extras: { useAuth: false },
      })
      .then((res: any) => {
        return {
          user: res?.user || {},
        };
      });
  }

  async checkUserAdminDiscount(data: { discountId: number, carNumber: string }): Promise<any> {
    return await ContextStatic.default.apiService.post(
      "/check/discount/coupon",
      { discount_id: data.discountId, car_number: data.carNumber },
      {
        extras: { useAuth: true },
      }
    );
  }
}

export const userService = UserService.getInstance();
